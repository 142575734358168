import i18n from 'i18next';
import * as _ from 'lodash';
import { Container, ITextStyle, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../../config';
import { EventTypes, FeatureState, IBonus } from '../../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../../gql/cache';
import type { IBetSettings } from '../../../gql/d';
import { getBetsSetting } from '../../../gql/fromFragment';
import { ResourceTypes } from '../../../resources.d';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../../utils';
import {
  buyBonusBetTextStyle,
  buyBonusBetValueTextStyle,
  buyBonusTotalCostTextStyle,
  buyBonusTotalCostValueTextStyle,
  titleBuyBonusTextStyles,
} from '../../buyBonus/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import CancelBtn from '../../controlButtons/cancelBtn';
import { UiButton } from '../../ui/uiButton';
import { Popup } from '../popup';
import { PopupController } from '../PopupController';

class BuyBonusPopup extends Popup {
  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState = FeatureState.FREE_SPINS;

  private isLandscape = false;

  private contentContainer = new Container();

  private okBtn: UiButton;

  private backBtn: UiButton;

  private cancelBtn = new CancelBtn();

  private titleText: TextField;

  private titleConfirmedText: TextField;

  private betContainer = new Container();

  private betText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private betValue: TextField;

  private buyBonusTotalCostText: TextField;

  private buyBonusTotalCostValue: TextField;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.x = 0;
    this.y = 0;
    this.visible = false;
    this.coinMultiplier = setSlotConfig().lineSets[0]!.coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.isConfirmed = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.titleText = this.initTitle();
    this.titleConfirmedText = this.initTitleConfirmed();
    this.betText = this.initBetText();
    this.betValue = this.initBetValue();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betAmountBackplate = this.initBetAmountBackplate();

    this.buyBonusTotalCostText = this.initBuyBonusTotalCostText();
    this.buyBonusTotalCostValue = this.initBuyBonusTotalCostValue();
    this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.backBtn = this.initBackBtn();
    this.betContainer.addChild(
      this.betAmountBackplate,
      this.betText.getText(),
      this.minusBtn,
      this.plusBtn,
      this.betValue.getText(),
    );
    this.contentContainer.addChild(
      this.titleText.getText(),
      this.titleConfirmedText.getText(),
      this.betContainer,
      this.buyBonusTotalCostText.getText(),
      this.buyBonusTotalCostValue.getText(),
      this.okBtn,
      this.backBtn,
    );
    this.addChild(this.contentContainer, this.cancelBtn);
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public override show(): void {
    super.show();
    this.visible = true;
  }

  // public close(): void {
  //   AudioApi.play({ type: ISongs.UI_ButtonClose });

  //   this.visible = false;
  //   eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  //   eventManager.emit(EventTypes.CLOSE_POPUP);
  // }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyBonusTitle', { spinsNumber: 10 }),
      1100,
      150,
      titleBuyBonusTextStyles as Partial<ITextStyle>,
    );
    title.text.y = -130;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);

    return title;
  }
  private initTitleConfirmed(): TextField {
    const title = new TextField(
      i18n.t<string>('buyBonusTitleConfirmed', { spinsNumber: 10 }),
      1100,
      150,
      titleBuyBonusTextStyles as Partial<ITextStyle>,
    );
    title.text.visible = false;
    title.text.y = -100;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);

    return title;
  }

  private initBetText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyBonusBetText'),
      400,
      100,
      buyBonusBetTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = -40;
    amountText.text.x = 0;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.y = 0;
    minusBtn.x = -175;
    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = 0;
    plusBtn.x = 175;

    return plusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.betBackground));
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      buyBonusBetValueTextStyle as Partial<ITextStyle>,
    );
    betValue.text.y = -10;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initBuyBonusTotalCostText(): TextField {
    const betValue = new TextField('Total Cost', 300, 100, buyBonusTotalCostTextStyle as Partial<ITextStyle>);
    betValue.text.y = 70;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initBuyBonusTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(FeatureState.FREE_SPINS),
      300,
      100,
      buyBonusTotalCostValueTextStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 110;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): void {
    this.cancelBtn.interactive = true;
    this.cancelBtn.btn.anchor.set(1, 0);
    this.cancelBtn.on('click', () => this.onCancel());
    this.cancelBtn.on('touchend', () => this.onCancel());
  }

  private onCancel() {
    this.isConfirmed = false;
    this.backBtn.visible = false;
    this.titleText.text.visible = true;
    this.betContainer.visible = true;
    this.titleConfirmedText.text.visible = false;
    this.handleButtonsPosition();
    AudioApi.play({ type: ISongs.UI_ButtonClose });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    PopupController.the.closeCurrentPopup();
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }
  private initOkBtn(): UiButton {
    const okBtn = new UiButton('confirm');
    okBtn.interactive = true;
    okBtn.y = 0;
    okBtn.x = 500;
    okBtn.on('click', this.handleClickOk.bind(this));
    okBtn.on('touchend', this.handleClickOk.bind(this));
    return okBtn;
  }

  private initBackBtn(): UiButton {
    const okBtn = new UiButton('back');
    okBtn.interactive = true;
    okBtn.y = -100;
    okBtn.x = 500;
    okBtn.visible = false;
    okBtn.on('click', this.handleClickBack.bind(this));
    okBtn.on('touchend', this.handleClickBack.bind(this));
    return okBtn;
  }

  private getBetAmount(betAmount: number): number {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  }

  private handleMinus(): void {
    const isMinBet = this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet;
    if (isMinBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
      eventManager.emit(EventTypes.UPDATE_BOTTOM_BAR_BET);
    }
  }

  private handlePlus(): void {
    const isMaxBet = this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet;
    if (isMaxBet) {
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
      eventManager.emit(EventTypes.UPDATE_BOTTOM_BAR_BET);
    }
  }

  private updateBets(): void {
    const bet = formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    });
    this.betValue.text.text = `${bet}`;
    this.buyBonusTotalCostValue.text.text = this.getTotalCost(FeatureState.FREE_SPINS);
  }

  private getTotalCost(featureState: FeatureState): string {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getBetValue(): number {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private getCoinAmount(featureState: FeatureState): number {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  }

  private handleClickOk(): void {
    AudioApi.play({ type: ISongs.UI_ButtonPress });
    if (!this.isNoFunds && !this.isConfirmed) {
      this.isConfirmed = true;
      this.backBtn.visible = true;
      this.titleText.text.visible = false;
      this.betContainer.visible = false;
      this.titleConfirmedText.text.visible = true;
      this.handleButtonsPosition();
    } else {
      if (this.isConfirmed) {
        this.startBuyFeature();
      }
    }
  }

  private startBuyFeature(): void {
    this.isConfirmed = false;
    this.onCancel();
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
  }

  private handleClickBack(): void {
    AudioApi.play({ type: ISongs.UI_ButtonPress });
    this.backBtn.visible = false;
    this.isConfirmed = false;
    this.titleText.text.visible = true;
    this.titleConfirmedText.text.visible = false;
    this.betContainer.visible = true;
    this.handleButtonsPosition();
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount - 1];
    const isMinBet = bet === this.betSettings!.minBet;
    const isMaxBet = bet === this.betSettings!.maxBet;

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.featureState));
    if (this.isNoFunds) {
      this.okBtn.setDisable(true);
    } else {
      this.okBtn.setDisable(false);
    }
    if (isMinBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (isMaxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  }

  private handleButtonsPosition(): void {
    this.backBtn.x = this.isLandscape ? 500 : -100;
    this.backBtn.y = this.isLandscape ? -80 : 350;

    if (this.isConfirmed) {
      this.okBtn.x = this.isLandscape ? 500 : 100;
      this.okBtn.y = this.isLandscape ? 80 : 350;
    } else {
      this.okBtn.x = this.isLandscape ? 500 : 0;
      this.okBtn.y = this.isLandscape ? 0 : 350;
    }
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;
    this.isLandscape = isLandscape;
    this.handleButtonsPosition();
    if (isLandscape) {
      this.contentContainer.scale.set(_width / 1688);
    } else {
      this.contentContainer.scale.set(_width / 780);
    }
    this.contentContainer.x = _width / 2;
    this.contentContainer.y = _height / 2;
  }
}

export default BuyBonusPopup;
