import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { Environments } from '@money.energy/audio-api/dist/d';
import { Loader } from '@money.energy/shared-components';
import { ELoaderStages } from '@money.energy/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@money.energy/utils-fe';
import { audioSprite, audioSpriteVolume, LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES } from '../../config';
import { EventTypes, GameMode, IAuthInput, IBonus, IUserBalance } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setIsAuthorized,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setUserLastBonusBet,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import type { IBet, IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  getBonuses,
  getSlotGql,
  getSlotLoadProgressInfoGql,
  getUserGql,
  slotBetGql,
  slotConfigGql,
  slotHistoryGql,
} from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import type { ISlotData } from '../../slotMachine/d';
import { isBuyFeatureEnabled, loadErrorHandler, loadPixiAssets, parseQuery, wait } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import styles from './loadScreen.module.scss';

const getCoinValue = (slotConfig: ISlotData) => {
  return setUserLastBetResult().id
    ? setUserLastBetResult().coinValue
    : slotConfig.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  if (setBrokenGame()) {
    return setCurrentBonus().coinAmount;
  }
  return setUserLastBetResult().id
    ? setUserLastBetResult().coinAmount
    : slotConfig.clientSettings.coinAmounts.default[0];
};

const getUserBalanceFn = async () => {
  const userBalance = await client.query<{ user: IUserBalance }>({
    query: getUserGql,
    fetchPolicy: 'network-only',
  });
  setUserBalance(userBalance.data.user);
  setCurrency(userBalance.data.user.balance.currency);
};

const getLastBetFn = async () => {
  const betsData = await client.query<{ bets: ISlotHistoryData }>({
    query: slotHistoryGql,
    variables: {
      input: { last: 1, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });
  const lastBet = betsData.data.bets.edges[0];

  if (lastBet) {
    if (lastBet.node.userBonus?.betId) {
      const bet = await client.query<{ bet: IBet }>({
        query: slotBetGql,
        variables: { input: { id: lastBet.node.userBonus.betId } },
        fetchPolicy: 'network-only',
      });
      setUserLastBetResult(bet.data.bet);
      setUserLastBonusBet(lastBet.node);
    } else {
      setUserLastBetResult(lastBet.node);
    }
  }
};

const getPurchasableBonusesFn = async () => {
  const bonusData = await client.query<{ bonuses: IBonus[] }>({
    query: getBonuses,
    variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  setBonuses(bonusData.data.bonuses);
};

const getSlotDataFn = async () => {
  const slotData = await client.query<{ slot: ISlotData }>({
    query: getSlotGql,
    variables: { input: { id: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  const { slot } = slotData.data;
  const slotConfig = {
    clientSettings: {
      ...slot.clientSettings,
      autoplay: {
        options: [10, 20, 30, 50, 100, 200, 300, 400, 500, 800, 1000, 2000],
        conditions: {
          stopOnAnyWin: {
            enabled: false,
          },
          stopIfFeatureIsWon: {
            enabled: false,
          },
          stopIfSingleWinExceeds: {
            enabled: false,
            multipliers: [],
          },
          stopIfBalanceDecreasesBy: {
            enabled: true,
            multipliers: [0, ...slot.clientSettings.autoplay.conditions.stopIfBalanceIncreasesBy.multipliers],
          },
          stopIfBalanceIncreasesBy: {
            enabled: true,
            multipliers: [0, ...slot.clientSettings.autoplay.conditions.stopIfBalanceIncreasesBy.multipliers],
          },
        },
      },
    },
    settings: slot.settings,
    previewImage: slot.previewImage,
    icons: slot.icons,
    reels: slot.reels,
    lineSets: slot.lineSets,
    lines: slot.lines,
    isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
  };
  const coinValue = getCoinValue(slot);
  const coinAmount = getCoinAmount(slot);

  setSlotConfig({
    ...setSlotConfig(),
    ...slotConfig,
  });
  setGameMode(GameMode.BASE_GAME);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  if (setUserLastBonusBet().id === '') {
    setWinAmount(setUserLastBetResult().result.winCoinAmount);
  } else {
    setWinAmount(setUserLastBonusBet().result.winCoinAmount);
  }

  setBetAmount(setCoinAmount() * slot.lineSets[0]!.coinAmountMultiplier);
};

// const checkBrokenGameFn = async () => {
//   const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
//     query: getUserBonuses,
//     variables: {
//       input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
//     },
//     fetchPolicy: 'network-only',
//   });
//   const userBonusesList = userBonusData.data.userBonuses;
//   const isActiveUserBonus = Boolean(userBonusesList.length);

//   if (isActiveUserBonus) {
//     const bonus = userBonusesList[0] as UserBonus;
//     const isFreeSpinsFeature = Boolean(!bonus!.data.freeSpinsFeature);
//     if (isFreeSpinsFeature) {
//       setCurrentBonus({
//         ...bonus,
//         isActive: true,
//         gameMode: GameMode.FREE_SPINS,
//         currentRound: bonus.roundsPlayed,
//         rounds: bonus.rounds + bonus.roundsPlayed,
//         totalWinAmount: 0,
//       });
//       setCurrentFreeSpinsTotalWin(0);
//     }

//     setBrokenGame(true);
//   }
// };

const LoadScreen: React.FC = () => {
  const pixiContainer = useRef<HTMLDivElement>(null);
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { data: slotLoadProgressInfo } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { isSoundOn } = slotConfig!;
  const [isShowContent] = useState(true);

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      setSlotConfig({
        ...setSlotConfig(),
        sessionId,
      });

      setIsAuthorized(!!slotLoadProgressInfo);
    },
  });

  useEffect(() => {
    const getUserBalance = getUserBalanceFn;
    const getPurchasableBonuses = getPurchasableBonusesFn;
    const getLastBet = getLastBetFn;
    // const checkBrokenGame = checkBrokenGameFn;
    const getSlotData = getSlotDataFn;
    const checkFontLoad = async (fontName: string) => {
      if (document.fonts) {
        try {
          await document.fonts.load(`10pt ${fontName}`);
          await document.fonts.ready;
          console.log(`${fontName} is loaded`);
        } catch (err) {
          console.error('Error loading fonts:', err);
        }
      } else {
        console.warn('Font Loading API is not supported in this browser.');
      }
    };

    const app = new PIXI.Application({
      width: 400,
      height: 400,
      backgroundColor: 0x000000,
    });

    if (pixiContainer.current) {
      pixiContainer.current.appendChild(app.view);
    }
    app.loader.add('aceLogo', './animations/aceLogo.json').load((loader, resources) => {
      console.log(loader, resources.aceLogo.spineData);
      if (resources.aceLogo.spineData) {
        const spineAnimation = new Spine(resources.aceLogo.spineData);
        spineAnimation.x = app.screen.width / 2;
        spineAnimation.y = app.screen.height / 2;
        spineAnimation.scale.set(0.5);
        app.stage.addChild(spineAnimation);
        spineAnimation.state.setAnimation(0, 'logo_init', false);

        checkFontLoad('Noto Sans').then(() => {
          new Loader({ asynchronous: false })
            .stage(10, ELoaderStages.AUTH, async (stage, _resources) => {
              const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
              const { data } = await getAuth({ variables: { input: { token, clientId } } });

              window.remoteStorage = remoteStorage;
              await remoteStorage.init(data?.auth.sessionId as string);
              rebuildStorageCache<IConfig>('config', {
                isSoundOn: setIsSoundOn,
                isTurboSpin: setIsTurboSpin,
              });
              setProgress({
                ...setProgress(),
                status: stage,
              });
            })
            .stage(30, ELoaderStages.RESOURCES, async (stage, _resources) => {
              await getUserBalance();
              await getPurchasableBonuses();
              await getLastBet();
              // await checkBrokenGame();
              await getSlotData();

              setProgress({
                ...setProgress(),
                status: stage,
              });
              spineAnimation.state.setAnimation(0, 'logo_step25', false);
            })
            .stage(40, ELoaderStages.PIXI_ASSETS, async (stage, _resources) => {
              await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
              setProgress({
                ...setProgress(),
                status: stage,
              });
              spineAnimation.state.setAnimation(0, 'logo_step50', false);
              await wait(500);
            })
            .stage(60, ELoaderStages.CUSTOM, async (stage, _resources) => {
              setProgress({
                ...setProgress(),
                status: stage,
              });
              spineAnimation.state.setAnimation(0, 'logo_step75', false);
              await wait(500);
            })
            .stage(80, ELoaderStages.AUDIO, async (_stage, _resources) => {
              AudioApi.initialize({
                audioSprite,
                audioVolume: audioSpriteVolume,
                restricted: false,
                isSoundEnabled: isSoundOn,
                onSuspended: setIsSuspended,
                audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
                environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
              }).then(() => {
                eventManager.emit(EventTypes.SOUND_INITIALIZED);
              });
              spineAnimation.state.setAnimation(0, 'logo_step100', false);
              await wait(500);
            })
            .onError(async (error, resources) => {
              loadErrorHandler(error, resources);
            })
            .onComplete(async (_resources) => {
              setProgress({
                ...setProgress(),
                status: 100,
              });
              eventManager.on(EventTypes.GAME_READY, () => {
                setProgress({
                  ...setProgress(),
                  wasLoaded: true,
                });
              });
            })
            .load();
        });
      }
    });

    return () => {
      app.destroy(true, { children: true });
    };
  }, []);

  if (!isShowContent) return null;

  return (
    <div className={styles['loadScreenWrapper']}>
      <div ref={pixiContainer} />
    </div>
  );
};

export default LoadScreen;
