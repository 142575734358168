import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Texture } from 'pixi.js';
import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, MAPPED_SYMBOLS_LAND_ANIMATIONS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetResult } from '../../gql/cache';
import { getFromMappedSymbol, nextTick } from '../../utils';
import { TextField } from '../components/TextField';
import { eventManager, multipliersStyle, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH, SLOTS_PER_REEL_AMOUNT } from '../config';

const getMultiplierByReelId = (reelId: number, multipliers: number[]) => {
  if (reelId % 2 === 0 && reelId / 2 < multipliers.length) {
    return multipliers[reelId / 2];
  }
};

class Slot extends Container {
  public id: number;

  public slotId: SlotId;

  private reelId: number | undefined;

  public textureSlotId: SlotId;

  public slot: Sprite;

  private landAnimation!: Spine;

  private multiplayer = new TextField('', 100, 100, multipliersStyle).getText();

  constructor(id: number, slotId: SlotId, reelId?: number) {
    super();

    this.id = id;
    this.slotId = slotId;
    this.reelId = reelId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
    this.slotId = slotId;
    this.slot = this.initSlot();
    this.multiplayer.anchor.set(0.5);
    this.multiplayer.scale.set(1.5);
    this.slot.addChild(this.multiplayer);
    this.addChild(this.slot);
  }

  private initSlot(): Sprite {
    const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.slotId)));
    if (this.slotId === SlotId.SC1) {
      const scatterWin = setBetResult()?.bet.data.features.scatterWin;
      if (scatterWin) {
        slot.texture = Texture.from(MAPPED_SYMBOLS[SlotId.SC].default);
      } else {
        slot.texture = Texture.from(MAPPED_SYMBOLS[SlotId.SC1].default);
      }
    }

    slot.anchor.set(0.5, 0.5);
    slot.scale.set(SLOT_SCALE);
    return slot;
  }

  public changeTexture(slotId: SlotId): void {
    this.slot.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.slot.texture = Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId].default);
    } else {
      this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);
    }
  }

  public onSlotStopped(): void {
    this.multiplayer.text = '';
    let m = 0;
    if (this.slotId === SlotId.SC1) {
      const isBonusMultipliers = setBetResult()?.bet.data.features.scatterWin;
      if (isBonusMultipliers) {
        m = getMultiplierByReelId(this.reelId!, isBonusMultipliers.multipliers)!;
        this.multiplayer.text = `x${m}`;
      }
    }
    this.playLandAnim(m);
  }

  private playLandAnim(_m: number): void {
    const isBonus = setBetResult()?.bet.data.features.scatterWin;
    if (MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]) {
      const animationSrc = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.src;
      const animationName = isBonus
        ? MAPPED_SYMBOLS_LAND_ANIMATIONS[SlotId.SC]?.animation
        : MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.animation;
      this.landAnimation = new Spine(Loader.shared.resources![animationSrc!]!.spineData!);

      if (_m) {
        const multiplayer = new TextField(`x${_m}`, 100, 100, multipliersStyle).getText();
        multiplayer.anchor.set(0.5);
        this.landAnimation.skeleton.findSlot('multiplier').currentSprite.texture = Texture.EMPTY;
        this.landAnimation.skeleton.findSlot('multiplier').currentSprite.addChild(multiplayer);
      }

      this.landAnimation.state.addListener({
        start: () => {
          nextTick(() => {
            this.slot.visible = false;
          });
        },
        complete: () => {
          nextTick(() => {
            if (this.landAnimation.state) this.landAnimation.destroy();
            this.slot.visible = true;
            eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
          });
        },
      });
      this.landAnimation.state.setAnimation(0, animationName!, false);
      this.addChild(this.landAnimation);
    } else {
      eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
    }
  }
}

export default Slot;
